const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://online-beauty-master-class.outcastgeek.tech',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: '/img/OGTech_Logo-white.svg',
    logoLink: 'https://online-beauty-master-class.outcastgeek.tech',
    title:
      "<a href='https://online-beauty-master-class.outcastgeek.tech'><img class='img-responsive' src='/img/OGTech_Logo-white.svg' alt='Learn logo' /></a>",
    githubUrl: 'https://github.com/OUTCASTGEEK-TECH/architectural-designs/tree/online-beauty-master-class',
    helpUrl: '',
    tweetText: '',
    social: `<li>
		    <a href="https://twitter.com/outcastgeek" target="_blank" rel="noopener">
		      <div class="twitterBtn">
		        <img src='/img/twitter-brands-block.svg' alt={'Twitter'}/>
		      </div>
		    </a>
		  </li>
			<li>
		    <a href="https://discord.gg/RTHcwt" target="_blank" rel="noopener">
		      <div class="discordBtn">
		        <img src='/img/discord-brands-block.svg' alt={'Discord'}/>
		      </div>
		    </a>
		  </li>`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction', // add trailing slash if enabled above
      '/codeblock',
    ],
    collapsedNav: [
      '/codeblock', // add trailing slash if enabled above
    ],
    links: [
      { text: 'OUTCASTGEEK TECH', link: 'https://outcastgeek.tech' },
      { text: 'STRIPE', link: 'https://stripe.com' },
      { text: 'FIREBASE', link: 'https://firebase.google.com/' },
      { text: 'WISTIA', link: 'https://wistia.com/' },
      { text: 'TWITCH', link: 'https://www.twitch.tv/' },
      { text: 'MAILGUN', link: 'https://www.mailgun.com/' },
      { text: 'DISQUS', link: 'https://disqus.com/' },
      { text: 'HEADLESS CMS', link: 'https://headlesscms.org/' },
      { text: 'NETLIFY', link: 'https://vercel.com/' },
      { text: 'VERCEL', link: 'hhttps://vercel.com/' },
    ],
    frontline: false,
    ignoreIndex: true,
    title:
      "<a href='https://outcastgeek.tech'>outcastgeektech </a><div class='greenCircle'></div><a href='https://outcastgeek.tech/contact/'>contact</a>",
  },
  siteMetadata: {
    title: 'Gatsby Gitbook Boilerplate | Hasura',
    description: 'Documentation built with mdx. Powering hasura.io/learn ',
    ogImage: null,
    docsLocation: 'https://github.com/OUTCASTGEEK-TECH/architectural-designs/tree/online-beauty-master-class/content',
    favicon: '/img/OGTech_Logo-white.svg',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'static/img/OGTech_Logo-white.svg',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
